<template>
  <div>

    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>部门管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card>
      <!--   添加分类   -->
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.d_name" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-button type="primary" @click="addDepartmentSelect" icon="el-icon-document-add" v-if="is_add">添加</el-button>
        </el-col>
      </el-row>
      <!--表格-->
      <el-table :data="departmentlist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="部门名称" prop="d_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="排序" prop="d_sort" show-overflow-tooltip></el-table-column>
        <el-table-column label="创建时间" prop="add_date" show-overflow-tooltip width="200px"></el-table-column>
        <el-table-column label="创建人" prop="add_uid" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-button type="primary" size="mini" v-if="is_edit" icon="el-icon-edit"
                       @click="DepartEditDialog(scope.row.d_id)" >
            </el-button>
            <!--删除-->
            <el-button type="danger" size="mini" v-if="is_del" icon="el-icon-delete-solid"
                       @click="removeDepartById(scope.row.d_id)">
            </el-button>

          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加分类对话框-->
    <el-dialog
        title="添加部门" :visible.sync="adddepartment" width="50%" @close="adddepartmentClose">
      <!--    添加分类的表单    -->
      <el-form :model="addDepartmentForm" :rules="addDepartmentFormRules"
               ref="addDepartmentFormRef" label-width="100px">

        <el-form-item label="部门名称：" prop="d_name">
          <el-input v-model="addDepartmentForm.d_name"></el-input>
        </el-form-item>
        <el-form-item label="选择部门：" prop="d_pid">
          <el-select v-model="value" placeholder="请选择" @change="addSelectValue" clearable>
            <el-option
                v-for="item in department1list"
                :key="item.d_id"
                :label="item.d_name"
                :value="item.d_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：" prop="d_sort">
          <el-input v-model="addDepartmentForm.d_sort"></el-input>
        </el-form-item>


      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adddepartment = false">取 消</el-button>
        <el-button type="primary" @click="adddepartmentUp" :loading="addloading">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑分类对话框-->
    <el-dialog
        title="编辑部门" :visible.sync="editdepartment" width="50%" @close="adddepartmentClose">
      <!--    添加分类的表单    -->
      <el-form :model="editDepartmentForm" :rules="addDepartmentFormRules"
               ref="addDepartmentFormRef" label-width="100px">

        <el-form-item label="部门名称：" prop="d_name">
          <el-input v-model="editDepartmentForm.d_name"></el-input>
        </el-form-item>
        <el-form-item label="选择部门：">
          <el-select v-model="editvalue" placeholder="请选择" @change="addSelectValue" clearable>
            <el-option
                v-for="item in department1list"
                :key="item.d_id"
                :label="item.d_name"
                :value="item.d_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：" prop="d_sort">
          <el-input v-model="editDepartmentForm.d_sort"></el-input>
        </el-form-item>


      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editdepartment = false">取 消</el-button>
        <el-button type="primary" @click="editdepartmentUp" :loading="editloading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addloading:false,
      editloading:false,
      //获取部门数据
      departmentlist: [],
      //获取一级部门数据
      department1list: [],
      value: '',
      editvalue:'',
      total:0,
      //页面权限
      DepartmentJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      //表格加载
      loading:true,
      //获取用户列表的参数对象
      querInfo: {
        page: 1,
        limit: 10,
        d_name:''
      },
      adddepartment: false,
      editdepartment: false,
      addDepartmentForm: {
        d_name: '',
        d_pid: '',
        d_sort: 0
      },
      editDepartmentForm: {
        d_name: '',
        d_pid: '',
        d_sort: 0
      },
      //添加仓库表单的验证规则对象
      addDepartmentFormRules: {
        d_name: [
          {required: true, message: '请输入部门的名称', trigger: 'blur'}
        ],
        d_sort: [
          {required: true, message: '请输入排序', trigger: 'blur'}
        ]
      },

    }
  },
  created() {
    this.getDepartmentList()
    this.getDepartmentJurisdiction()
  },
  methods: {
    //获取权限接口
    async getDepartmentJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.DepartmentJurisdiction=res.data
      this.DepartmentJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    // 获取部门数据
    async getDepartmentList() {
      this.loading=true
      const {data: res} = await this.$http.get('department/getList',{params: this.querInfo})
      if (res.code != 200) return this.$message.error('获取部门信息失败')
      this.departmentlist = res.data.data
      this.total = res.data.total
      this.loading=false
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getDepartmentList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getDepartmentList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getDepartmentList()
    },
    // 获取一级部门数据
    async getDepartment1List() {
      const {data: res} = await this.$http.get('department/getMainList')
      if (res.code != 200) return this.$message.error('获取一级部门信息失败')
      this.department1list = res.data
    },
    //点击添加按钮
    addDepartmentSelect() {
      this.getDepartment1List()
      this.adddepartment = true
    },
    // 添加  department/add

    //监听
    adddepartmentClose() {
      this.addDepartmentForm.d_name = '',
          this.addDepartmentForm.d_pid = '',
          this.addDepartmentForm.d_sort = 0
      // this.$refs.addFormRef.resetFields()？
      if (this.$refs.addFormRef !== undefined) {
        this.$refs.addFormRef.resetFields();
      }
    },
    //select 发生变化时触发
    addSelectValue() {
      this.addDepartmentForm.d_pid = this.value
    },

    //点击确定 提交表单
    adddepartmentUp() {
      this.$refs.addDepartmentFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('department/add', this.addDepartmentForm)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success("添加成功")
        this.adddepartment = false
        this.getDepartmentList()
      })
    },
    //1 删除
    async removeDepartById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`department/del/${id}`)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      this.getDepartmentList()
    },
    //department/find/1 查找
    getDepartment1list(){

    },
    //打开
    async DepartEditDialog(id){
      this.editdepartment=true
      this.getDepartment1List()
      const {data:res} = await this.$http.get(`department/find/${id}`)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.editDepartmentForm=res.data
      if (res.data.d_pid===0){
        this.editvalue=''
      }else{
        this.editvalue=res.data.d_pid
      }

    },

    //department/edit 提交编辑
    async editdepartmentUp(){
      this.editloading=true
      const {data:res} = await this.$http.post('department/edit', this.editDepartmentForm)
      this.editloading=true
      if (res.code!=200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.editdepartment=false

      this.getDepartmentList()

    }
  }
}
</script>

